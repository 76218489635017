<template>
	<main class="page-container">
		<!-- 메뉴이동 로직 추가 S (20220714 - hib)  -->
		<div v-if="isMobile" class="box_select is-static">
			<button type="button" id="select_btn" @click="click">커뮤니티</button>
			<ul id="select_list">
				<li v-for="(item, idx) in menus" :key="idx">
					<a :href="item.path">- {{ item.title }}</a>
				</li>
			</ul>
		</div>
		<!-- 메뉴이동 로직 추가 E (20220714 - hib)  -->
		<section class="scomm-section">
			<div class="container">
				<!-- 상단 제목 검색 -->
				<div class="scomm-detail-header">
					<h2 class="scomm-detail-header__title">상세페이지 제작 지원사업 커뮤니티</h2>
					<div class="scomm-main-search">
						<form action="#">
							<fieldset>
								<legend class="sr-only">커뮤니티 검색</legend>

								<input type="search" class="scomm-main-search__input" placeholder="커뮤니티를 검색해 보세요!  " />
								<button type="submit" class="scomm-main-search__submit"><i class="icon-scomm-search"></i><span class="sr-only">검색</span></button>
							</fieldset>
						</form>
					</div>
				</div>
				<!-- //상단 제목 검색 -->
				<div class="scomm-detail-flex">
					<!-- s: 왼쪽 영역 -->
					<div class="flex-0">
						<div class="scomm-detail-info__thumb">
							<img src="~@/assets/img/@tmp/tmp_notice_image_02@3x.jpg" alt="사진" />
						</div>
						<!-- 웹접근성 구현 탭 -->
						<Tabs>
							<TabList>
								<Tab>커뮤니티정보</Tab>
								<Tab>나의 활동</Tab>
							</TabList>
							<TabPanels>
								<TabPanel>
									<!-- 커뮤니티정보 -->
									<div class="scomm-detail-info__panel">
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-member"></i>
												<span class="text">멤버수</span>
											</dt>
											<dd>22명</dd>
										</dl>
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-board"></i>
												<span class="text">게시물 수</span>
											</dt>
											<dd>130건</dd>
										</dl>
									</div>
									<!-- //커뮤니티정보 -->
								</TabPanel>
								<TabPanel>
									<!-- 나의 활동 -->
									<div class="scomm-detail-info__panel">
										<!-- 사용자 정보 -->
										<div class="scomm-detail-user">
											<div class="scomm-detail-user__photo">
												<img src="" alt="" />
											</div>
											<div class="scomm-detail-user__text">
												<span class="scomm-detail-user__name">김가치</span>
												<span class="scomm-detail-user__date">2023.02.01 14:32</span>
											</div>
										</div>
										<!-- //사용자 정보 -->
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-user"></i>
												<span class="text">방문</span>
											</dt>
											<dd>361회</dd>
										</dl>
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-myarticle"></i>
												<span class="text"><a href="">내가 쓴 글 보기</a></span>
											</dt>
											<dd>1개</dd>
										</dl>
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-mycomm"></i>
												<span class="text"><a href="">내가 쓴 댓글 보기</a></span>
											</dt>
											<dd>5개</dd>
										</dl>
										<dl class="scomm-detail-info__dl">
											<dt>
												<i class="icon-info-like"></i>
												<span class="text"><a href="">좋아요 한 글 보기</a></span>
											</dt>
											<dd>1개</dd>
										</dl>
									</div>
									<!-- //나의 활동 -->
								</TabPanel>
							</TabPanels>
						</Tabs>

						<a href="#" class="button-default is-rounded is-primary">
							<span class="is-desktop">가입하기</span>
							<span class="is-mobile"><i class="icon-info-join"></i>가입</span>
						</a>
						<nav class="scomm-detail-info__links">
							<ul>
								<li>
									<a href=""><i class="icon-info-home"></i><span>Home</span></a>
								</li>
								<li>
									<a href=""><i class="icon-info-notice"></i><span>공지사항</span></a>
								</li>
								<li>
									<a href=""><i class="icon-info-data"></i><span>자료실</span></a>
								</li>
							</ul>
						</nav>
					</div>
					<!-- e: 왼쪽 영역 -->
					<!-- s: 오른쪽 영역 -->
					<div class="flex-1">
						<section class="bbs-detail">
							<!-- s: 게시판 본문 헤더 -->
							<div class="bbs-detail-header">
								<div class="bbs-detail-header__back">
									<router-link to="../notice">
										<i class="icon-bbs-back"></i>
										<span class="text">공지사항</span>
									</router-link>
								</div>
								<h3 class="bbs-detail-header__title">공지사항입니다.</h3>
								<!-- 사용자 정보 -->
								<div class="scomm-detail-user">
									<div class="scomm-detail-user__photo">
										<img src="" alt="" />
									</div>
									<div class="scomm-detail-user__text">
										<span class="scomm-detail-user__name">관리자</span>
										<span class="scomm-detail-user__date">2023.02.01 14:32</span>
										<span class="scomm-detail-user__count">조회 25</span>
									</div>
								</div>
								<!-- //사용자 정보 -->
							</div>
							<!-- e: 게시판 본문 헤더 -->
							<!-- s: 게시판 본문 바디 -->
							<div class="bbs-detail-body">
								<!-- s: 게시판 본문 콘텐츠 -->
								<div class="bbs-detail-content content">
									<p>게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다.게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다.게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다.게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다.게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다.게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다.게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다. 게시물 내용입니다.</p>
									<p>첨부파일을 다운받아서 사용하세요.</p>
									<p>다른 자료는 자료실목록에서 확인 할 수 있습니다.</p>
									<ul>
										<li>목록테스트</li>
										<li>목록테스트</li>
										<li>목록테스트</li>
										<li>목록테스트</li>
										<li>목록테스트</li>
									</ul>
									<ol>
										<li>목록테스트</li>
										<li>목록테스트</li>
										<li>목록테스트</li>
										<li>목록테스트</li>
										<li>목록테스트</li>
									</ol>
								</div>
								<!-- e: 게시판 본문 콘텐츠 -->
							</div>
							<!-- e: 게시판 본문 바디 -->
							<div class="bbs-detail-buttons buttons">
								<router-link to="../notice" class="button-default is-large is-rounded is-primary">목록</router-link>
							</div>
						</section>
					</div>
					<!-- e: 오른쪽 영역 -->
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import { mapGetters } from 'vuex';
import { Tabs, Tab, TabList, TabPanels, TabPanel } from 'vue-accessible-tabs';

export default {
	name: 'ScommunityBbsDetail',
	components: {
		Tabs,
		Tab,
		TabList,
		TabPanels,
		TabPanel,
	},
	data: () => ({
		totalCount: 100,
		pageSize: 10,
		pageListSize: 5,
		pageNo: 8,
		previewImage: null,
	}),
	computed: {
		...mapGetters('common', ['isMobile']),
		queries() {
			const result = {};
			return result;
		},
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
};
</script>
